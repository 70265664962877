.tx-websitetemplate-tabcontainer {
    .slick-list {
        overflow: visible;
    }
}

.tabs-navigation {
    background-color: #FFF;
    margin: 30px 0 56px;
    min-width: 570px;
    display: table;
    padding: 15px 30px 13px;

    button {
        color: #000;
        border: 0;
        background: transparent;
        padding: 0;
        margin-right: 40px;
        position: relative;

        &.active,
        &:hover,
        &:focus {
            color: var(--c-highlight--blue);
            text-decoration: none;
        }

        &.active::after {
            content: ' ';
            display: block;
            position: absolute;
            height: 2px;
            background-color: var(--c-highlight--blue);
            left: 0;
            right: 0;
        }
    }
}

@media (--sm-viewport) {
    .tabs-navigation {
        width: 100%;
        min-width: 0;

        button {
            display: block;
            margin: 0;
            width: 100%;
            &.active::after {
                display: none;
            }
        }

        button + button {
            border-top: 1px solid var(--c-highlight--blue);
        }
    }
}