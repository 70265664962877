body {
    font-family: 'Cabin', sans-serif;
    font-size: 18px;
    line-height: 27px;
}

a {
    color: var(--c-primary);

    &:hover,
    &:focus {
        color: var(--c-highlight--blue);
    }
}

h1, .h1 {
    font-size: 48px;
    line-height: 58px;
    font-weight: bold;
}

h2, .h2 {
    font-size: 36px;
    line-height: 47px;
    font-weight: bold;
    color: var(--c-primary);
}

h3, .h3 {
    font-size: 27px;
    line-height: 35px;
    font-weight: bold;
    color: var(--c-primary);
    margin-bottom: 10px;
}

h4, .h4 {
    font-size: 19px;
    line-height: 25px;
    font-weight: bold;
    margin-bottom: 15px;
}

h5, .h5 {
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
}

p {
    margin: 0 0 15px;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-white,
.text-white > .btn-primary {
    color: #FFF;
}

.text-white > .btn-primary::after {
    background-color: #FFF;
}

.text-light {
    font-size: 15px;
    line-height: 23px;
    color: var(--c-light-font);
}

.text-uppercase {
    text-transform: uppercase;
}

@media (--sm-viewport) {

    h1, .h1 {
        font-size: 24px;
        line-height: 1.2;
    }

    h2, .h2 {
        font-size: 22px;
        line-height: 1.2;
    }

    h3, .h3 {
        font-size: 20px;
    }
}
