/* Default minimal slick css - Begin*/

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}


/* Default minimal slick css - End*/


/* Dots */
.slick-dots {
    position: absolute;
    padding: 0;
    margin: 0;
    text-align: center;
    left: 0;
    right: 0;
    z-index: 5;
    bottom: 15px;
}

.slick-dots li {
    display: inline-block;
}

.slick-dots li + li {
    margin-left: 10px;
}

.slick-dots > li:only-child {
    display: none;
}

.slick-dots button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    font-size: 0px;
    text-indent: 999999px;
    width: 10px;
    height: 10px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    opacity: 0.5;
    overflow: hidden;
    padding: 0;
    margin: 0;
    background-color: #D0D0D0;
}

.slick-dots .slick-active button {
    background-color: var(--c-highlight--blue);
}

/* arrows */
.slick-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;

    &::before {
        font-family: FontAwesome;
        line-height: 1;
        display: inline-block;
        content: "\f104";
        color: var(--c-primary);
    }
}

.slick-prev {
    left: 0;

    &::before {
        content: "\f104";
    }
}

.slick-next {
    right: 0;

    &::before {
        content: "\f105";
    }
}

/* slider caption */
.slider-item {
    padding-bottom: 45px;
    position: relative;
}

.tx-websitetemplate-content-slider .slick-dots {
    display: table;
    left: auto;
    right: 30px;
    bottom: 17px;
}

.slider-item .slider-caption {
    position: absolute;
    bottom: 0;
    max-width: 264px;
    background-color: #FFF;
    padding: 15px 15px 1px 0;
}


@media (--sm-viewport) {
    .tx-websitetemplate-content-slider .colContent:last-child .ce-bodytext {
        padding-bottom: 90px;
    }

    .tx-websitetemplate-content-slider blockquote {
        margin-left: 0;
        margin-right: 0;
    }

    .tx-websitetemplate-content-slider .slick-dots,
    .news-slider ul.slick-dots {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .tx-websitetemplate-content-slider .slider-caption {
        padding-top: 0;
        bottom: 30px;
    }


    .tx-websitetemplate-content-slider .slick-slide {
        padding-bottom: 65px;
    }

}