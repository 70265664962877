.ce-align-left {
    text-align: left;
}

.ce-align-center {
    text-align: center;
}

.ce-align-right {
    text-align: right;
}

.ce-table td,.ce-table th {
    vertical-align: top;
}

.ce-textpic,.ce-image,.ce-nowrap .ce-bodytext,.ce-gallery,.ce-row,.ce-uploads li,.ce-uploads div {
    overflow: hidden;
}

.ce-left .ce-gallery,.ce-column {
    float: left;
}

.ce-center .ce-outer {
    position: relative;
    float: right;
    right: 50%;
}

.ce-center .ce-inner {
    position: relative;
    float: right;
    right: -50%;
}

.ce-right .ce-gallery {
    float: right;
}

.ce-gallery figure {
    display: table;
    margin: 0;
}

.ce-gallery figcaption {
    display: table-caption;
    caption-side: bottom;
}

.ce-gallery img {
    display: block;
}

.ce-gallery iframe {
    border-width: 0;
}

.ce-border img,.ce-border iframe {
    border: 2px solid #000000;
    padding: 0px;
}

.ce-intext.ce-right .ce-gallery,.ce-intext.ce-left .ce-gallery,.ce-above .ce-gallery {
    margin-bottom: 10px;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 10px;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 10px;
}

.ce-below .ce-gallery {
    margin-top: 10px;
}

.ce-column {
    margin-right: 10px;
}

.ce-column:last-child {
    margin-right: 0;
}

.ce-row {
    margin-bottom: 10px;
}

.ce-row:last-child {
    margin-bottom: 0;
}

.ce-above .ce-bodytext {
    clear: both;
}

.ce-intext.ce-left ol,.ce-intext.ce-left ul {
    padding-left: 40px;
    overflow: auto;
}

.ce-headline-left {
    text-align: left;
}

.ce-headline-center {
    text-align: center;
}

.ce-headline-right {
    text-align: right;
}

.ce-uploads {
    margin: 0;
    padding: 0;
}

.ce-uploads li {
    list-style: none outside none;
    margin: 1em 0;
}

.ce-uploads img {
    float: left;
    padding-right: 1em;
    vertical-align: top;
}

.ce-uploads span {
    display: block;
}

.ce-table {
    width: 100%;
    max-width: 100%;
}

.ce-table th,.ce-table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
}

.ce-table thead th {
    border-bottom: 2px solid #dadada;
}

.ce-table th,.ce-table td {
    border-top: 1px solid #dadada;
}

.ce-table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

.ce-table-bordered th,.ce-table-bordered td {
    border: 1px solid #dadada;
}

.frame-space-before-extra-small {
    margin-top: 1em;
}

.frame-space-before-small {
    margin-top: 2em;
}

.frame-space-before-medium {
    margin-top: 3em;
}

.frame-space-before-large {
    margin-top: 4em;
}

.frame-space-before-extra-large {
    margin-top: 5em;
}

.frame-space-after-extra-small {
    margin-bottom: 1em;
}

.frame-space-after-small {
    margin-bottom: 2em;
}

.frame-space-after-medium {
    margin-bottom: 3em;
}

.frame-space-after-large {
    margin-bottom: 4em;
}

.frame-space-after-extra-large {
    margin-bottom: 5em;
}

.frame-ruler-before:before {
    content: '';
    display: block;
    border-top: 1px solid rgba(0,0,0,0.25);
    margin-bottom: 2em;
}

.frame-ruler-after:after {
    content: '';
    display: block;
    border-bottom: 1px solid rgba(0,0,0,0.25);
    margin-top: 2em;
}

.frame-indent {
    margin-left: 15%;
    margin-right: 15%;
}

.frame-indent-left {
    margin-left: 33%;
}

.frame-indent-right {
    margin-right: 33%;
}

.ce-textpic.ce-full-width img,.ce-image.ce-full-width img,.ce-textmedia.ce-full-width img,.tx-basetemplate-imgPropScale img {
    width: 100%;
    height: auto;
}

.ce-textpic.ce-full-width .ce-column,.ce-image.ce-full-width .ce-column,.ce-textmedia.ce-full-width .ce-column,.tx-basetemplate-imgPropScale .csc-textpic-imagewrap {
    float: none !important;
}

.ce-full-width .ce-column figure,.ce-image.ce-full-width .ce-column .image,.tx-basetemplate-imgPropScale figure,.tx-basetemplate-imgPropScale figure.csc-textpic-imagewrap {
    display: block !important;
}

.ce-flex {
    display: flex;
    align-items: center;
}

.ce-flex.ce-right {
    flex-direction: row-reverse;
}

.ce-border-radius img {
    border-radius: 5px;
}
