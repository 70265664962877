.news-column-list {
    display: flex;
    flex-wrap: wrap;

    .img-wrap {
        position: relative;

        img {
            display: block;
            margin: 0 auto;
        }
    }

    .date {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 18px;
        background-color: #FFF;
        line-height: 1;
        padding: 8px 15px 0 0;

        > span {
            font-weight: bold;
            font-size: 48px;
            display: block;
        }
    }

    hr {
        height: 2px;
        background-color: #000;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    footer {
        font-size: 12px;
        font-style: italic;
        line-height: 1.2;
    }

    .more {
        margin-top: 30px;
    }
}

.news-slider {
    padding-bottom: 120px;

    .slick-dots {
        left: auto;
        right: 0;
    }
}

.ColumnList .news-column-list article {
    margin-bottom: 60px;
}

@media (--sm-viewport) {
    .news-slider {
        padding-bottom: 80px;
        margin-bottom: 30px;
    }
}