#sidebar {
    position: fixed;
    top: 150px;
    right: 0;
    background-color: #FFF;
    width: 46px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 10;

    a {
        color: var(--c-primary);
        display: flex;
        text-align: center;
        line-height: 1;
        height: 46px;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
            color: var(--c-highlight--blue);
            text-decoration: none;
        }
    }

    a + a {
        border-top: 1px solid #E6E6E6;
    }

    .highlight-icon  {
        background-color: var(--c-highlight--blue);
        color: #FFF;
        transition:  300ms ease-in-out;
        &:hover,
        &:focus {
            background-color: var(--c-primary);
            color: #FFF;
        }
    }

    .fa-mobile {
        font-size: 30px;
    }

    .fa-envelope {
        font-size: 20px;
    }

    .fa-map-marker {
        font-size: 26px;
    }
}