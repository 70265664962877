fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

label {
    font-weight: bold;
}

form {
    input[type="text"],
    textarea,
    select {
        border: 1px solid #dadada;
        margin-top: 8px;
        padding: 6px 12px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        width: 100%;
    }

    input[type="text"],
    textarea,
    select,
    .checkbox {
        margin-top: 5px;
        margin-bottom: 20px;
        display: block;
    }
}

textarea {
    resize: none;
    height: 181px;
}

.checkbox {

    input[type="checkbox"] {
        position: absolute;
        visibility: hidden;
    }

    input[type="checkbox"] + label {
        position: relative;
        padding-left: 40px;
        display: block;
    }

    input[type="checkbox"] + label::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
        width: 24px;
        height: 24px;
        border: 1px solid #17b7e2;
        line-height: 24px;
        pointer-events: none;
        cursor: pointer;
        background-color: #FFFFFF;
    }

    input[type="checkbox"]:checked + label::after {
        content: '';
        position: absolute;
        width: 13px;
        height: 7px;
        top: 8px;
        background: transparent;
        left: 6px;
        border: 2px solid #17b7e2;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        border-radius: 0;
        margin-top: 0;
    }
}

