#header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    transition: background-color 100ms ease, box-shadow 100ms ease;
    background-color: #FFF;

    > .container {
        background-color: #FFF;
        padding: 15px;
        border-radius: var(--default-border-radius);

        > .row {
            display: flex;
        }
    }

    .nav-container {
        position: static;
    }

    nav,
    #main-nav {
        height: 100%;
    }

    #language-nav {
        position: absolute;
        right: 30px;
        display: flex;
        font-size: 14px;
        z-index: 10;
        align-items: center;
        color: #747474;

        ul {
            list-style-type: none;
            display: none;
            margin: 8px 0 0 0;
            padding: 0;
            background: #FFF;
            position: absolute;
            border: 1px solid;
            border-radius: 2px;
            z-index: 99;
            top: 100%;
            right: 0;

            li {
                padding: 5px 10px 5px 30px;
                background: #FFF;
                margin: 0;
                position: relative;

                &::before {
                    content: ' ';
                    display: block;
                    background: url('/_assets/4cea7fecd25cac2c44718a164f71ed78/Images/Flags/at.svg') no-repeat;
                    background-size: 20px auto;
                    position: absolute;
                    left: 5px;
                    top: 11px;
                    width: 20px;
                    height: 15px;
                }
            }

            li:nth-child(2)::before {
                background-image: url('/_assets/4cea7fecd25cac2c44718a164f71ed78/Images/Flags/ru.svg');
            }

            li + li {
                border-top: 1px solid;
            }

            a,
            a:hover,
            a:focus {
                text-decoration: none;
            }

            .active {
                color: var(--c-primary);
            }

            &::before {
                content: ' ';
                width: 10px;
                height: 10px;
                border: 1px solid;
                transform: rotate(45deg);
                position: absolute;
                right: 10px;
                top: -5px;
                background: #FFF;
                z-index: -1;
            }

        }

        li {
            margin: 0;
        }

        .active a {
            color: var(--c-highlight--blue);
            font-weight: bold;
        }

        a:hover {
            text-decoration: underline;
        }
    }

    #language-btn {
        appearance: none;
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        box-shadow: none;
        display: flex;
        align-items: center;

        &::before {
            content: ' ';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-image: url('/_assets/4cea7fecd25cac2c44718a164f71ed78/Images/Misc/globe.svg');
            margin-top: -1px;
        }

        &::after {
            content: '\f0d7';
            display: inline-block;
            font-family: FontAwesome;
            margin-left: 4px;
        }

        &:hover,
        &:focus {
            color: var(--c-highlight--blue);
        }
    }

}

.fixed-menu #header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
}

@keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

#scroll-down {
    position: absolute;
    right: 15px;
    bottom: 155px;
    background: transparent;
    appearance: none;
    box-shadow: none;
    z-index: 2;
    border: 0;
    padding: 15px;
    color: var(--c-highlight--blue);
    transition: color 300ms ease-in-out;

    &::before {
        content: '\f175';
        display: inline-block;
        font-family: "FontAwesome";
        font-size: 30px;
        font-weight: 300;
        animation: bounce 2s infinite;
    }

    &:hover,
    &:focus {
        color: #FFF;
    }

    @media (--sm-viewport) {
        display: none;
    }
}

[lang="ru"] #scroll-down {
    bottom: 170px;
}

#header-intro {
    position: relative;

    .banner-slide::after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.18));
        height: 250px;
        @media (--sm-viewport) {
            display: none;

        }
    }
}

#eyecatcher {
    position: absolute;
    bottom: 40px;
    left: 10%;
    width: 300px;
    height: auto;
}

#banner-slider {

    .slick-dots {
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
        z-index: 20;
    }

    .slick-arrow {
        width: 50px;
        height: 60px;
        background-color: var(--c-primary);
        text-align: center;
        line-height: 22px;
    }

    .slick-arrow::before {
        color: #FFF;
        font-size: 50px;
    }
}

.banner-slide {
    height: 100vh;
    background-position: center center;
    background-size: cover;

    > .container {
        height: 100%;
        position: relative;
    }

    h1, .h1 {
        color: #FFF;
        position: relative;
        z-index: 1;
    }

    .header-intro-text {
        color: #FFF;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        width: 80%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding-bottom: 80px;

        @media (--md-viewport) {
            width: 68%;
        }
    }

    &.narrow {
        height: 70vh;
    }

}

.header-intro-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;

    .text {
        float: right;
        position: relative;
        padding: 20px 30px;
        width: 300px;
        max-width: 100%;
        z-index: 10;

        .h4 {
            margin-top: 0;
        }

        * {
            z-index: 1;
            position: relative;
        }

        &::after {
            content: ' ';
            background-color: #FFF;
            left: 0;
            top: 0;
            bottom: 0;
            width: 999%;
            position: absolute;
            border-top-left-radius: var(--default-border-radius);
        }
    }
}

@media (--desktop-viewport) {
    body.sub-menu-open {
        overflow: hidden;
    }


    body.sub-menu-open::after {
        content: ' ';
        display: block;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
    }

    body.sub-menu-open,
    .sub-menu-open #header {
        padding-right: 17px;
    }

    .sub-menu-open #sidebar {
        right: 17px;
    }

    body.safari.sub-menu-open,
    .safari.sub-menu-open #header {
        padding-right: 0;
    }

    .safari.sub-menu-open #sidebar {
        right: 0;
    }
}

@media (--md-viewport) {
    #banner-slider {
        .slick-arrow {
            display: none !important;
        }
    }
}

@media (--sm-viewport) {
    #header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: #FFF;

    }

    #eyecatcher {
        left: 50%;
        margin-left: -150px;
        bottom: -270px;
    }

    .logo-link img {
        width: 111px;
        height: auto;
        margin: 15px 0;
    }

    #header > .container {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
    }

    #header > .container > .row {
        display: block;
    }

    .nav-container {
        position: static;
    }

    .header-intro-box {
        position: static;
        border-bottom: 1px solid var(--c-highlight--blue);
        display: none;
    }

    .banner-slide .header-intro-text {
        display: none;
    }

    .banner-slide,
    .banner-slide.narrow {
        height: 56.25vw;
    }

    #header-intro {
        margin-bottom: 30px;
    }

    #header-intro.with-slider-text {
        &::after {
            bottom: 140px;
        }

        #banner-slider {
            padding-bottom: 140px;
            border-bottom: 1px solid var(--c-primary);

            .slick-list {
                overflow: visible
            }

            .slick-dots {
                bottom: 0;
            }
        }

        .banner-slide {
            .header-intro-text {
                width: 100%;
                display: block;
            }

            .container {
                height: auto;
            }
        }

        .banner-slide .h1,
        .banner-slide h1 {
            text-shadow: none;
            color: var(--c-primary);
            font-size: 20px;
            margin: 20px 0;
        }

        .banner-slide > .container {
            top: 100%;
        }
    }
}
