.parsley-errors-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: red;
    font-weight: bold;
}

.tx-powermail {
    .container-fluid {
        padding: 0;
    }
    .powermail_fieldwrap:not(.powermail_fieldwrap_type_submit) .powermail_field
    {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .errorHolder
        {
            order: 10;
        }
    }
}