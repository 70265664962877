/* vietnamese */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Fonts/cabin/v26/u-4i0qWljRw-PfU81xCKCpdpbgZJl6XvptnsF3-OA6Fw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Fonts/cabin/v26/u-4i0qWljRw-PfU81xCKCpdpbgZJl6Xvp9nsF3-OA6Fw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    src: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Fonts/cabin/v26/u-4i0qWljRw-PfU81xCKCpdpbgZJl6XvqdnsF3-OAw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    src: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Fonts/cabin/v26/u-4i0qWljRw-PfU81xCKCpdpbgZJl6XvptnsF3-OA6Fw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    src: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Fonts/cabin/v26/u-4i0qWljRw-PfU81xCKCpdpbgZJl6Xvp9nsF3-OA6Fw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    src: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Fonts/cabin/v26/u-4i0qWljRw-PfU81xCKCpdpbgZJl6XvqdnsF3-OAw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
    margin: 0;
    padding: 0;
    -ms-overflow-style: scrollbar;
}

body {
    color: #000;
    font-family: sans-serif;
    font-size: 15px;
    line-height: 2rem;
    @media (--sm-viewport) {
        padding-top: 66px;
    }
}

body.mobileMenuOpen {
    overflow: hidden;
    position: fixed;
}

#site {
    overflow: hidden;
}

.no-stage {
    margin-top: 130px;

    @media (--sm-viewport) {
       margin-top: 60px;
    }
}

* {
    outline: none;
}

ul, ol, dl {
    margin-top: 15px;
    margin-bottom: 15px;

    li,
    dt {
        margin-bottom: 5px;
    }
}

#overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
}

#loader {
    display: none;
    background: svg-load('../../Images/ajax-loader.svg') no-repeat center;
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    z-index: 11;
    width: 200px;
    height: 200px;
}

/*Safari Bugfix flex:wrap*/
.row {
    &:before {
        content: none;
    }

    &:after {
        content: '';
    }
}

/* Embeded Video Full Width */
.video-embed {
    width: 100%;
    display: block;
    position: relative;

    &::before {
        content: '';
        padding-top: calc(100% / 480 * 270);
        display: block;
    }

    .video-embed-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

/* Square Mobile Images */
.frame-square {
    position: relative;

    &:after {
        content: '';
        padding-top: 100%;
        display: block;
    }
}

@media (--xs-viewport) {
    .frame-square-xs {
        overflow: hidden;

        .imgPropScale {
            background-image: none !important;
            position: relative !important;
            height: auto !important;

            &::after {
                content: "";
                padding-top: 100%;
                display: block;
                width: 100vw;
                position: relative;
                top: 0;
                left: 0;
            }

            img {
                visibility: visible;
                height: 100%;
                width: auto;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                position: absolute;
            }
        }
    }
}

/* Space before and after */
div.frame-space-before-large {
    margin-top: 80px;
    @media (--xs-viewport) {
        margin-top: 50px;
    }
}

div.frame-space-after-large {
    margin-bottom: 80px;
    @media (--xs-viewport) {
        margin-bottom: 50px;
    }
}

div.frame-space-before-medium {
    margin-top: 50px;
    @media (--xs-viewport) {
        margin-top: 30px;
    }
}

div.frame-space-after-medium {
    margin-bottom: 50px;
    @media (--xs-viewport) {
        margin-bottom: 30px;
    }
}

div.frame-space-before-small {
    margin-top: 30px;
    @media (--xs-viewport) {
        margin-top: 20px;
    }
}

div.frame-space-after-small {
    margin-bottom: 30px;
    @media (--xs-viewport) {
        margin-bottom: 20px;
    }
}

[class*="frame-space-before"] {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5 {
        margin-top: 0
    }
}

[class*="frame-space-after"] {
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5 {
        margin-bottom: 0
    }
}

/* Image Helper */
.imgPropScale {

    & > img,
    & > a > img,
    & > picture > img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.imgFullCover {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media (--sm-viewport) {
        background-position: center top;
    }

    & > a {
        margin: 0 !important;
        border: none !important;
        padding: 0 !important;

        &:focus {
            outline: 0;
        }

        &::after {
            display: none;
        }
    }

    & > img,
    & > picture > img {
        visibility: hidden;
    }
}

.ce-intext .ce-bodytext *:first-child,
.ce-intext .ce-bodytext *:first-child > * {
    margin-top: 0;
}

.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}

@media (--xs-viewport) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid;

        & > table {
            margin-bottom: 0;

            & > thead,
            & > tbody,
            & > tfoot {
                & > tr {
                    & > th,
                    & > td {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.gm-style > .gmnoprint:not(.gm-bundled-control),
.gm-style-cc,
.gm-svpc {
    display: none;
}

.gm-bundled-control > .gmnoprint {
    display: block;
}

.tx-contentblocks-options-container {
    background-size: cover;
    background-position: center center;
}

div.ce-textpic,
div.ce-text {
    overflow: visible;
}

.checklist {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        position: relative;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            background: url(/_assets/4cea7fecd25cac2c44718a164f71ed78/Images/Misc/bulletpoint.png) no-repeat;
            background-size: 18px;
            left: -30px;
            top: 3px;
        }
    }
}

@media (--md-viewport) {
    .checklist {
        padding-left: 30px;
    }
}

.bg-primary {
    background-color: var(--c-primary);
    color: var(--c-primary-contrast);
    position: relative;

    a {
        color: var(--c-primary-contrast);
    }

    .btn-secondary > span::after,
    .btn-primary::after {
        background-color: var(--c-primary-contrast);
    }

    .btn-secondary::after {
        color: var(--c-primary-contrast);
    }

    .btn-secondary:hover::after,
    .btn-secondary:focus::after {
        color: var(--c-highlight--blue);
    }
}

.expand-bg-right {
    * {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1920px;
        background-color: inherit;
    }
}

#cms-content img {
    max-width: 100%;
    height: auto;
}

.frame-space-after-extra-large {
    margin-bottom: 8em;
}

.frame-space-after-negative-space {
    margin-bottom: -100px;
    @media (--sm-viewport) {
        margin-bottom: 0;
    }
}

.ce-left.ce-flex .ce-gallery {
    margin-right: 18px;
}


/* Fix Bug in IE : images too big */
.ce-gallery {
    .ce-outer,
    .ce-inner,
    .ce-row,
    .ce-column,
    .video,
    .image {
        width: 100%;
    }
}

div.ce-gallery figure {
    display: block;
}

#to-top {
    position: fixed;
    right: 40px;
    bottom: 40px;
    border: 2px solid #FFF;
    color: #FFF;
    border-radius: 100%;
    appearance: none;
    background-color: var(--c-primary);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition: 300ms ease-in-out;
    width: 50px;
    height: 50px;

    &::before {
        content: '\f106';
        display: inline-block;
        font-family: "FontAwesome";
    }

    &:hover,
    &:focus {
        background-color: var(--c-highlight--blue);
    }

    @media (--sm-viewport) {
        right: 15px;
        bottom: 15px;
    }
}
