.btn-primary,
.btn-filled,
.btn-secondary > span {
    display: inline-block;
}

.btn-secondary,
.btn-filled {
    display: block;
    position: relative;
    padding-right: 40px;
}


.btn-primary,
.btn-secondary {
    font-weight: bold;
    color: #000;

    &:hover,
    &:focus {
        color: var(--c-highlight--blue);
        text-decoration: none;
    }
}

/* underline*/
.btn-primary::after,
.btn-secondary > span::after {
    content: ' ';
    display: block;
    height: 2px;
    width: 100%;
    background-color: #000;
}

.btn-primary:hover::after,
.btn-primary:focus::after,
.btn-secondary:hover > span::after,
.btn-secondary:focus > span::after {
    background-color: var(--c-highlight--blue);
}

/* btn-secondary arrow icon */
.btn-secondary::after,
.btn-filled::after {
    content: '\f105';
    display: inline-block;
    font-family: FontAwesome;
    height: 28px;
    line-height: 24px;
    width: 28px;
    border-radius: 100%;
    border: 2px solid;
    text-indent: 2px;
    text-align: center;
    position: absolute;
    right: 0;
    bottom: 0;
}

.btn-filled {
    background-color: var(--c-primary);
    color: #FFF;
    border: 2px solid transparent;
    transition: background-color 300ms ease;
    padding: 7px 60px 5px 20px;

    &::after {
        top: 6px;
        right: 10px;
    }

    &:hover,
    &:focus {
        background-color: transparent;
        color: var(--c-primary);
        border-color: var(--c-primary);
    }
}