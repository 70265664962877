#main-nav,
#main-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mobileMenuButton {
    display: none;
}

/*First Layer*/
#main-nav {
    display: flex;
    justify-content: space-between;

    > li {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
    }

    a {
        font-weight: bold;
        color: #000;
        position: relative;

        &[aria-expanded="true"],
        &.active,
        &:hover,
        &:focus {
            text-decoration: none;
            color: var(--c-highlight--blue);
        }

        &.active::before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: var(--c-highlight--blue);
        }
    }
}

/*Second Layer*/
#main-nav a[aria-expanded="true"] + ul {
    display: block;
}

#main-nav ul {
    display: none;
    position: absolute;
    white-space: nowrap;
    border-radius: var(--default-border-radius);
    top: 100%;
    left: -30px;
    padding: 26px;
    background-color: #FFF;
    margin-top: 18px;

    li + li {
        margin-top: 24px;
    }
}

@media (--sm-viewport) {
    .mobileMenuButton {
        display: block;
        background-color: transparent;
        color: var(--c-primary);
        border: 0;
        padding: 4px;
        font-size: 30px;
        position: absolute;
        right: 15px;
        top: 13px;
    }

    .nav-container {
        display: none;
    }

    .mobileMenuOpen .nav-container {
        display: block;
        position: fixed;
        top: 67px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background-color: #FFF;
    }

    #main-nav {

        height: auto;
        display: block;
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 50px;

        /*First Layer & General*/

        li {
            display: block;
            margin: 0;
            height: auto;
            border-top: 1px solid var(--c-primary);
        }

        > li:last-child {
            border-bottom: 1px solid var(--c-primary);
        }

        [aria-haspopup="true"]::after {
            content: '\f107';
            display: inline-block;
            font-family: FontAwesome;
            position: absolute;
            top: 9px;
            right: 15px;
            font-size: 24px;
            pointer-events: none;
            font-weight: normal;
        }

        [aria-haspopup="true"][aria-expanded="true"]::after {
            content: '\f106';
        }

        a {
            display: block;
            padding: 13px 15px 12px;
            line-height: 1.1;

            &::before {
                display: none !important;
            }
        }


        /*Second Layer*/

        ul {
            position: static;
            margin: 0;
            padding: 0;
        }

        ul a {
            padding-left: 30px;
        }

        ul li + li {
            margin-top: 0;
        }

        .btn-primary::after {
            display: none;
        }
    }

}