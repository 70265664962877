#skiplinks {
    position: fixed;
    top: 50px;
    width: 230px;
    z-index: 1001;

    a {
        color: #FFF;
        background: var(--c-primary);
        font-weight: bold;
        font-size: 2rem;
        padding: 10px 20px;
        display: inline-block;
        text-decoration: none;
        margin: 10px;
        position: absolute;
        min-width: 160px;

        &::after {
            background: svg-load('../../../../../node_modules/@fortawesome/fontawesome-pro/svgs/light/chevron-right.svg', fill=#FFF) no-repeat center;
            background-size: 22px !important;
            content: '';
            padding-left: 10px;
        }
    }
}