.tx-contentblocks {
    section.accordion {
        &.open {
            & > header a::after,
            & > header button::after {
                content: '\f068';
            }
        }

        &.no-content {
            > header a::after,
            > header button::after {
                display: none;
            }

            > header {
                cursor: default;
            }

            button, a {
                cursor: auto;
                color: inherit !important;
                padding-right: 0 !important;
                pointer-events: none;
            }
        }

        & > header {
            padding: 0;
            border-bottom: 2px solid #000;

            &::after {
                display: none;
            }

            h1, h2, h3, h4, h5, h6 {
                margin: 0;
            }

            a, button {
                color: #000;
                padding: 15px 38px 15px 82px;
                background-size: 66px;
                background-repeat: no-repeat;
                background-position: left center;
                min-height: 80px;
                display: block;
                align-items: center;

                &::after {
                    content: '\f067';
                    display: inline-block;
                    font-family: FontAwesome;
                    border-radius: 100%;
                    border: 2px solid;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    margin-top: -14px;
                    height: 27px;
                    width: 27px;
                    line-height: 25px;
                    text-align: center;
                    font-size: 14px;
                    text-indent: 1px;
                }

                &:hover,
                &:focus,
                &[aria-expanded="true"] {
                    color: var(--c-highlight--blue);
                    text-decoration: none;
                }

                &.no-image {
                    padding-left: 0;
                }
            }
        }

        > .descText {
            padding: 15px;
        }

        .info {
            float: right;
            font-weight: normal;
            margin-right: 15px;
            color: var(--c-highlight--blue);
            @media (--sm-viewport) {
                display: block;
                float: none;
                margin-top: 10px;
            }
        }
    }
}

.accordion-large-text {
    font-size: 24px;
    font-weight: bold;
    margin-left: 15px;
    @media (--sm-viewport) {
        margin-left: 0;
        font-size: inherit ;
    }
}

#start-teaser {
    text-align: center;
    margin-bottom: 80px;
    margin-left: -15px;
    margin-right: -15px;

    .circle {
        border-radius: 100%;
        background-color: var(--c-accent);
        color: #FFF;
        position: relative;
        width: 200px;
        height: 200px;

        .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        span {
            display: table;
            border-bottom: 2px solid #FFF;
            margin: 0 auto 6px;
            font-size: 32px;
            padding-bottom: 10px;
        }

        footer {
            text-transform: uppercase;
        }
    }

    .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;

        img {
            margin: 0 auto 25px;
            display: block;
        }
    }

    p {
        font-size: 19px;
        font-weight: bold;
    }

    .border {
        display: inline-block;
        border-bottom: 2px solid;
        padding-bottom: 4px;
    }

    .slick-track {
        display: flex;
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: 300ms ease-in-out;

        &:hover,
        &:focus {
            text-decoration: none;
            color: var(--c-highlight--blue);
        }
    }

    .order-1 {
        order: 1;
    }

    .order-2 {
        order: 2;
    }

    .order-3 {
        order: 3;
    }

    .order-4 {
        order: 4;
    }

    .order-5 {
        order: 5;
    }

    .slick-dots {
        bottom: -50px;
    }

    @media (max-width: 1199px) {
        .order-1 {
            order: 0;
        }

        .order-2 {
            order: 0;
        }

        .order-3 {
            order: 0;
        }

        .order-4 {
            order: 0;
        }

        .order-5 {
            order: 0;
        }
    }
}

.circle-list {
    list-style-type: none;
    margin: 80px auto;
    padding: 0;
    display: table;

    li {
        margin: 0 0 64px 0;
        padding: 0 0 0 90px;
        position: relative;
        min-height: 64px;
        display: flex;
        align-items: center;
        font-weight: bold;

        .circle {
            border-radius: 100%;
            border: 2px solid;
            width: 64px;
            height: 64px;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: 1;
            box-shadow: 0 0 0 10px #FFF;
            background-color: #FFF;

            .text-uppercase {
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 1px;
            }
        }
    }

    li + li::before {
        content: ' ';
        position: absolute;
        left: 33px;
        width: 1px;
        height: 200%;
        top: -100%;
        border-right: 2px solid;
        z-index: -1;
    }

    @media (--sm-viewport) {
        margin: 50px 0;
        display: block;
    }
}

.quote-alternative-slider {
    max-width: 668px;
    margin: 50px auto;
}

.quote-alternative {
    border-top: 3px solid;
    max-width: 668px;
    padding-left: 90px;
    position: relative;
    padding-top: 50px;

    &::before {
        content: '\f075';
        display: inline-block;
        font-family: "FontAwesome";
        width: 66px;
        height: 66px;
        border-radius: 100%;
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-size: 24px;
        border: 2px solid;
        position: absolute;
        left: 0;
        top: 50px;
    }
}