#footer {
    background-color: var(--c-light-tone);
    position: relative;
}

.footer-bottom {
    background-color: #000;
    padding: 15px 0;
    color: #FFF;
}

.footer-top {
    padding: 32px 0 60px;
}


#footer nav {
    display: inline-block;
}

#footer-year {
    margin-right: 12px;
}

#footer-nav {
    margin: 0 -12px;
    padding: 0;
    list-style-type: none;

    li {
        display: inline-block;
        margin: 0 12px;

    }

    a {
        color: #FFF;
    }

    a {
        color: #FFF;
    }
}

